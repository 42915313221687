import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "../../../css/crown.module.css";
import Img from "gatsby-image";
import { motion } from "framer-motion";
import Carousel from "nuka-carousel";
import bgImage from "../../../images/crown-residence/bg-pattern.jpg";

const ulVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.1 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const liVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 500, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 500 }
    }
  }
};

const PointsMobile = ({ list = [], title = "", fluid }) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (ref.current) {
      let current = ref.current;
      const observer = new IntersectionObserver(([entry]) => {
        // Update our state when observer callback fires
        if (entry.isIntersecting) {
          setOpen(true);
          observer.unobserve(current);
        }
      });
      observer.observe(current);
      return () => {
        observer.unobserve(current);
      };
    }
  }, []);

  return (
    <div className="col-12 mb-5 pb-5">
      <Img
        className="w-100 d-block d-md-none mb-4"
        imgStyle={{ borderRadius: "15px" }}
        fluid={fluid}
      />
      <motion.div animate={open ? "open" : "closed"} ref={ref}>
        <div className="relative">
          <div className={styles.pointTitleRound}></div>
          <motion.div variants={liVariants} className={styles.pointTitle}>
            {title}
          </motion.div>
        </div>
        <motion.ul className={styles.pointList} variants={ulVariants}>
          {list.map(x => (
            <motion.li variants={liVariants} className={styles.pointLi} key={x}>
              {x}
            </motion.li>
          ))}
        </motion.ul>
      </motion.div>
    </div>
  );
};

const Points = ({ list = [], title = "", idx, onInView, active, fluid }) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (ref.current) {
      let current = ref.current;
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when observer callback fires
          if (entry.isIntersecting) {
            setOpen(true);
            onInView(idx);
          }
        },
        { rootMargin: "30px 0px 30px 0px" }
      );
      observer.observe(current);
      return () => {
        observer.unobserve(current);
      };
    }
  }, [onInView, idx]);

  return (
    <div className={styles.content}>
      <Img
        className="w-100 d-block d-md-none mb-4"
        imgStyle={{ borderRadius: "15px" }}
        fluid={fluid}
      />
      <motion.div animate={open ? "open" : "closed"}>
        <div className="relative">
          <div className={styles.pointTitleRound}></div>
          <div className={styles.pointTitle}>{title}</div>
        </div>
        <motion.ul ref={ref} className={styles.pointList} variants={ulVariants}>
          {list.map(x => (
            <motion.li variants={liVariants} className={styles.pointLi} key={x}>
              {x}
            </motion.li>
          ))}
        </motion.ul>
      </motion.div>
    </div>
  );
};

const Image = ({ fluid }) => {
  return (
    <div className={styles.highImgCon}>
      <Img className={styles.highImg} fluid={fluid} />
    </div>
  );
};

const Highlights = ({ features = [] }) => {
  const [active, setActive] = useState(0);

  const onInView = useCallback(idx => {
    setActive(idx);
  }, []);

  return (
    <>
      <section className="pt-5 pb-5 d-md-none">
        <div className="container-fluid">
          <div className="row">
            {features.map((x, idx) => (
              <PointsMobile
                idx={idx}
                list={x.points}
                title={x.title}
                fluid={x.image.childImageSharp.fluid}
                onInView={onInView}
                active={active}
                key={x.title}
              />
            ))}
          </div>
        </div>
      </section>
      <div className="d-none d-md-block">
        <section className="relative">
          <div className="abs-full">
            <img src={bgImage} alt="" className={styles.stickyBg} />
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-lg-5 offset-lg-1">
                {features.map((x, idx) => (
                  <Points
                    idx={idx}
                    list={x.points}
                    title={x.title}
                    fluid={x.image.childImageSharp.fluid}
                    onInView={onInView}
                    active={active}
                    key={x.title}
                  />
                ))}
              </div>
              <div className="col-md-6 pr-md-0 d-none d-md-block">
                <div
                  className={`${styles.stickySection} ${styles.sectionHeight}`}
                >
                  <div className={styles.carousel}>
                    <Carousel
                      slideIndex={active}
                      dragging={false}
                      withoutControls
                      swiping={false}
                      speed={1000}
                    >
                      {features.map((x, idx) => (
                        <Image
                          idx={idx}
                          fluid={x.image.childImageSharp.fluid}
                          key={x.title}
                        />
                      ))}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Highlights;
