import React, { useEffect, useState, useRef } from "react";
import styles from "../../../css/crown.module.css";
import useOnScreen from "../../hooks/layout/useOnScreen";

const AcresText = () => {
  const ref = useRef();
  const onScreen = useOnScreen(ref);
  const [scrollY, set] = useState(0);

  useEffect(() => {
    if (onScreen) {
      function onScroll() {
        set(window.scrollY);
      }
      document.addEventListener("scroll", onScroll);
      return () => {
        document.removeEventListener("scroll", onScroll);
      };
    }
  }, [onScreen]);

  return (
    <section className={styles.acresSection} ref={ref}>
      <div className="container">
        <div
          className={styles.acresText}
          style={{
            backgroundPositionY: scrollY,
            transition: "backgroundPositionY 0.7s ease"
          }}
        >
          5.1 ACRES OF GREEN OPEN SPACES, LANDSCAPING AND LARGE SUNKEN GARDEN
        </div>
      </div>
    </section>
  );
};

export default AcresText;
